import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import ActionCreator from "../ActionCreator";
import * as Icon from "./Icon";
import Selectors from "../Selectors";
import Form from "./Form";
import Image from "./Image";

class PhoneDetail extends React.Component {
  state = {
    smsList: [],
    selectedSmsId: 0,
    order: null
  };
  componentDidMount() {
    this._fetchSmsList();
  }

  render() {
    let { smsList, selectedSmsId } = this.state;
    let { instance, css } = this.props;
    let {
      created,
      max_msg,
      number,
      owner,
      id,
      product, //product
      valid,
      valid_duration,
      order
    } = instance;

    if (this.state.order) {
      order = this.state.order;
    }

    let expired = moment(created)
      .add(valid_duration, "d")
      .format("YYYY/MM/DD");
    let day_left = -moment().diff(expired, "days");
    let selectedIndexOfSmsListArr = smsList
      .map(sms => sms.id)
      .indexOf(selectedSmsId);

    return (
      <Wrapper css={css}>
        <Icon.Autorenew
          onClick={() => this._fetchSmsList()}
          size={30}
          color={"#607d8b"}
          style={{ float: "right", marginRight: 10, cursor: "pointer" }}
        />
        <div className="phone-section">
          <div className="left">
            <p> {product.name} </p>
          </div>
          <div className="center">
            {(() => {
              if (order && order.is_subscribe_order) {
                if (this._hasCanceledSubscribe(order)) {
                  return (
                    <Label css="margin-bottom: 10px; background-color: #9da4a9;">
                      已成功取消訂閱
                    </Label>
                  );
                } else {
                  return <Label css="margin-bottom: 10px;">此為訂閱門號</Label>;
                }
              }
            })()}
            <PhoneNumber css="margin-bottom: 10px;">
              <Icon.Phone size={25} color={"#575757"} />
              {number}
              {valid === false && `(已逾期)`}
            </PhoneNumber>

            <PhoneContent>
              還可接收
              <span style={{ color: "#D36D45" }}>
                {max_msg - smsList.length}
              </span>
              則簡訊 ／ 已接收
              <span style={{ color: "#D36D45" }}>{smsList.length}</span>
              則簡訊
              <br />
              到期日：
              <span style={{ color: "#D36D45" }}>{expired}</span>
              <br />
              購買日期：
              <span style={{ color: "#D36D45" }}>
                {instance.created.split("T")[0].replace(/-/g, "/")}
              </span>
            </PhoneContent>
          </div>

          {valid === true && (
            <div className="right">
              {order &&
                order.is_subscribe_order &&
                !this._hasCanceledSubscribe(order) &&
                (this._isApplyingUnSubscribe(order) ? (
                  <div
                    className="button-invalid"
                    style={{ marginBottom: 10, backgroundColor: "#65a1cc" }}
                  >
                    取消訂閱此門號申請中
                  </div>
                ) : (
                  <div
                    className="button"
                    style={{
                      marginBottom: 10,
                      color: "#65a1cc",
                      border: "1px solid #65a1cc"
                    }}
                    onClick={() => this._toUnsubscribe(order.id)}
                  >
                    取消訂閱此門號
                  </div>
                ))}

              {this._checkCartAlreadyHasBoughtThisNumber(number) ? (
                <div className="button-invalid" style={{ marginBottom: 10 }}>
                  加購簡訊已在購物車
                </div>
              ) : (
                <div
                  className="button"
                  style={{ marginBottom: 10 }}
                  onClick={() =>
                    this._addItemToCart(product, number, "ext_msg")
                  }
                >
                  加購簡訊額度
                </div>
              )}

              {this._checkCartAlreadyHasExtendedThisNumber(number) ? (
                <div className="button-invalid">延長此號碼已在購物車</div>
              ) : (
                <div
                  className="button"
                  onClick={() =>
                    this._addItemToCart(product, number, "ext_dur")
                  }
                >
                  延長使用期
                </div>
              )}
            </div>
          )}
        </div>
        {smsList && smsList.length == 0 ? (
          <div
            style={{
              padding: 40,
              display: "flex",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <div style={{ marginBottom: 20, fontSize: 14, color: "#575757" }}>
              收件夾為空
            </div>
            <Icon.Sms size={50} color={"#D55421"} />
          </div>
        ) : (
          <div className="sms-section">
            <div className="smss">
              {smsList &&
                smsList.map(sms => {
                  return (
                    <SmsSender
                      key={sms.id}
                      selected={selectedSmsId === sms.id}
                      onClick={() => this.setState({ selectedSmsId: sms.id })}
                    >
                      <Icon.Email
                        size={23}
                        color={selectedSmsId === sms.id ? "#D36D45" : "#575757"}
                        style={{ marginRight: 10 }}
                      />
                      {sms.sender}
                    </SmsSender>
                  );
                })}
            </div>
            <div className="sms-content">
              {(() => {
                if (selectedIndexOfSmsListArr < 0) {
                  return (
                    <div className="content">
                      <div>請點選訊息</div>
                    </div>
                  );
                } else {
                  return (
                    <>
                      <div className="data">
                        寄件人：{smsList[selectedIndexOfSmsListArr].sender}
                        <br />
                        時間：
                        {
                          `${smsList[selectedIndexOfSmsListArr].timestamp}`.split(
                            "T"
                          )[0]
                        }{" "}
                        {`${smsList[selectedIndexOfSmsListArr].timestamp}`.slice(
                          11,
                          16
                        )}
                      </div>

                      {/* TODO: change to real sender */}
                      <div className="content">
                        訊息：
                        {smsList[selectedIndexOfSmsListArr].message}
                      </div>
                    </>
                  );
                }
              })()}
            </div>
          </div>
        )}
      </Wrapper>
    );
  }

  _fetchSmsList = () => {
    let { appActions, instance } = this.props;
    appActions
      .fetchSmsListById(instance.id)
      .then(smsList => this.setState({ smsList }));
  };

  _toUnsubscribe = async orderId => {
    let { appActions } = this.props;
    this.setState({ loading: true });

    try {
      await appActions.toUnsubscribeOrder(orderId);
      let order = await appActions.getOrder(orderId);
      this.setState({ order });
    } catch (err) {
      console.warn(err);
    }

    this.setState({ loading: false });
  };

  _isApplyingUnSubscribe = order => {
    if (order.rtn_app) {
      return true;
    } else {
      return false;
    }
  };

  _hasCanceledSubscribe = order => {
    if (order.rtn_app && order.rtn_app.is_sub_canceled) {
      return true;
    } else {
      return false;
    }
  };

  _checkCartAlreadyHasExtendedThisNumber = number => {
    let { itemsInCart } = this.props;

    let result = itemsInCart.items
      .filter(item => item.type === "ext_dur")
      .map(item => item.number)
      .indexOf(number);

    if (result >= 0) {
      return true;
    } else {
      return false;
    }
  };

  _checkCartAlreadyHasBoughtThisNumber = number => {
    let { itemsInCart } = this.props;

    let result = itemsInCart.items
      .filter(item => item.type === "ext_msg")
      .map(item => item.number)
      .indexOf(number);

    if (result >= 0) {
      return true;
    } else {
      return false;
    }
  };

  _addItemToCart = (product, number, type) => {
    let { appActions, navActions } = this.props;
    this.setState({ loading: true });
    if (type === "ext_dur") {
      appActions
        .addItemToCart({
          product_id: product.id,
          quantity: 1,
          period: "3m",
          type: type,
          number: number
        })
        .catch(err => {
          console.warn(err);
        })
        .then(() => this.setState({ loading: false }));
    } else if (type === "ext_msg") {
      appActions
        .addItemToCart({
          product_id: product.id,
          quantity: 1,
          msg_qty: "min",
          type: type,
          number: number
        })
        .catch(err => {
          console.warn(err);
        })
        .then(() => this.setState({ loading: false }));
    }
  };
}

const Wrapper = styled.div`
  ${props => props.css || ""}

  & > .phone-section {
    display: flex;
    flex-direction: row;
    padding: 30px;
    border-bottom: 1px solid #cccccc;

    & > .left {
      p {
        word-break: break-all;
      }
      flex: 0 0 140px;
      margin-bottom: 10px;
    }

    & > .center {
      flex: 1;
      flex-direction: column;
      margin-bottom: 10px;
    }

    & > .right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      max-width: 200px;
      padding: 10px;
    }

    & .button {
      cursor: pointer;
      text-align: center;
      border: 1px solid #d55421;
      padding: 5px 30px;
      color: #d55421;
      border-radius: 15px;
      font-size: 12px;

      :hover {
        background-color: #fef1ec;
      }
    }

    & .button-invalid {
      text-align: center;
      padding: 5px 30px;
      color: #fff;
      background: lightgrey;
      border-radius: 15px;
      font-size: 12px;
    }

    @media screen and (max-width: 800px) {
      flex-direction: column;
      & > .left {
        flex: 0 0 25px;
      }
    }
  }

  & > .sms-section {
    background-color: #f9f9f9;
    height: 50vh;
    display: flex;
    border-bottom: 1px solid #cccccc;

    & > .smss {
      border-right: 1px solid #cccccc;
      overflow-y: auto;
    }

    & > .sms-content {
      flex: 1;
      font-size: 1em;
      & > .data {
        color: #575757;
        padding: 20px 0px;
        margin: 0px 17px;
        border-bottom: 1px solid #cccccc;
      }

      & > .content {
        color: #575757;
        padding: 20px 0px;
        margin: 0px 17px;
      }
    }

    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }
`;

const SmsSender = styled.div`
  cursor: pointer;
  line-height: 8px;
  font-size: 16px;
  font-weight: 900;
  color: ${props => (props.selected ? "#D36D45" : "#575757")};
  padding: 17px 0px;
  margin: 0px 17px;
  border-bottom: 1px solid #cccccc;
`;

const PhoneNumber = styled.div`
  letter-spacing: 1.25px;
  font-weight: 900;
  font-size: 20px;
  color: #575757;
  ${props => props.css}
`;

const PhoneContent = styled.div`
  font-size: 14px;
  color: #575757;
`;

const Label = styled.div`
  display: inline-block;
  background-color: #65a1cc;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-size: 13px;
  ${props => props.css}
`;

export default connect(
  (state, ownProps) => ({
    itemsInCart: !Selectors.getCartData(state)
      ? []
      : Selectors.getCartData(state)
  }),
  ActionCreator
)(PhoneDetail);
