import React, { Component } from "react";
import styled from "styled-components";

class SelectSearch extends Component {
  state = {
    open: false,
    search: "",
    searchedChoices: []
  };

  render() {
    let {
      disabled,
      value,
      onChange,
      required,
      choices,
      fnGetLabel,
      fnSearchChoices
    } = this.props;
    let { open, search } = this.state;

    return (
      <Wrapper>
        <div
          className="display-label"
          onClick={() => this.setState({ open: true })}
        >
          {this._getLabelByValue(value)}
        </div>
        <Dialog open={open} className={`${open ? "active" : ""}`}>
          <div
            className="backdrop"
            onClick={() => {
              this.setState({ open: false });
            }}
          />
          {open && (
            <div className="content" onClick={e => e.stopPropagation()}>
              <div className="search-wrapper">
                <input
                  placeholder="search for..."
                  onChange={e => this.setState({ search: e.target.value })}
                />
                {/*
                 * TODO:
                 * add a button, to explicit trigger `props.fnSearchChoices`,
                 * and set the result into `state.searchedChoices`
                 */}
              </div>
              <div className="choices-wrapper">
                {/*
                 * TODO:
                 * if end-user types nothing (search is empty), render all `props.choices`
                 * else render `state.searchedChoices`
                 */}
                {choices
                  .filter(
                    c =>
                      fnGetLabel(c)
                        .toLowerCase()
                        .indexOf(search.toLowerCase()) > -1
                  )
                  .map((c, idx) => (
                    <div
                      className="choice"
                      key={idx}
                      onClick={e => {
                        e.stopPropagation();
                        onChange({ target: { value: c.value } });
                        this.setState({ open: false });
                      }}
                    >
                      {fnGetLabel(c)}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </Dialog>
      </Wrapper>
    );
  }

  _getLabelByValue = value => {
    let { choices, fnGetLabel } = this.props;
    if (!value) {
      return "---";
    }
    let selectedChoice = choices.find(c => c.value === value);
    return fnGetLabel(selectedChoice);
  };
}

let Wrapper = styled.div`
  & .display-label {
    cursor: pointer;
    background-color: #e4e4e4;
    border-radius: 6px;
    height: 30px;
    width: 200px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    justify-content: space-between;

    &::after {
      content: "";
      border: 7px solid transparent;
      border-top: 10px solid white;
      border-bottom-width: 0px;
    }
  }
`;

let Dialog = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  display: flex;

  transform: translateX(-100vw);
  transition: 0s ease-in transform 0.5s;
  &.active {
    transform: translateX(0);
    transition: 0s ease-in transform 0s;
  }

  & .backdrop {
    background-color: rgba(0, 0, 0, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: ${props => (props.open ? 1 : 0)};
    transition: 0.2s ease-in opacity;
  }

  & .content {
    background-color: white;
    width: 80%;
    min-width: 600px;
    max-width: calc(100% - 20px);
    min-height: 300px;
    max-height: calc(100% - 60px);
    overflow: auto;
    border-radius: 5px;
    z-index: 1001;
    position: relative;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 700px) {
      min-width: initial;
    }

    & > .choices-wrapper {
      overflow: auto;
      flex-grow: 1;

      & > .choice {
        padding: 6px 20px;
        border-bottom: 1px solid #f3f3f3;
        cursor: pointer;
      }
    }

    & > .search-wrapper {
      padding-top: 20px;
      padding: 20px 20px 10px 20px;
      border-bottom: 1px solid lightgrey;
      top: 0px;

      & > input {
        width: 100%;
        border: 1px solid #979797;
        border-radius: 6px;
        padding: 8px 10px;
        outline: none;
        color: #101d1f;
      }
    }
  }
`;

export default SelectSearch;
