import React from "react";
import styled from "styled-components";
import * as Icon from "../Icon";
import ActionCreator from "../../ActionCreator";
import { connect } from "react-redux";
import AppConfig from "../../Config";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;

  & > .admin-rwd-toggle {
    display: none;

    @media screen and (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: 20px;
      bottom: 20px;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      border: solid 2px white;
      background-color: ${AppConfig.global.themeColor};
    }
  }

  & > .admin-sidebar {
    @media screen and (max-width: 600px) {
      position: fixed;
      height: 100%;
      left: ${props => (props.rwdSidebarOpen ? "0px" : "-200px")};
      transition: left 200ms;
    }

    width: 200px;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
    background-color: #e6e6e6;
    z-index: 1;

    .logout-button {
      cursor: pointer;
      margin: 30px;
      text-align: center;
      border: 1px solid #d55421;
      padding: 5px 43px;
      color: #d55421;
      border-radius: 15px;
      font-size: 14px;

      :hover {
        background-color: #fef1ec;
      }
    }

    .site-title {
      padding: 10px;
      font-weight: bold;
    }

    .entry {
      padding: 10px;
      display: flex;
    }

    .entry label {
      flex: 1;
    }

    .entry.selected {
      color: white;
      background-color: darkgrey;
    }

    .entry.level2 {
      padding-left: 25px;
    }

    .entry:hover {
      color: white;
      background-color: blue;
      cursor: pointer;
    }
  }

  & > .admin-tab-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    overflow-y: auto;
  }
  ${props => props.css}
`;

class CmsDashboard extends React.Component {
  static defaultProps = {
    Header: "Revtel Admin",
    entryFunctionHandler: () => 0
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedEntry: this.props.entries[0],
      rwdSidebarOpen: false
    };
  }

  render() {
    let { entries, css, appActions } = this.props;
    let { selectedEntry, rwdSidebarOpen } = this.state;

    return (
      <Wrapper rwdSidebarOpen={rwdSidebarOpen} css={css}>
        <div className="admin-sidebar">
          {this._renderHeader()}

          {entries.map(entry => (
            <CmsEntry
              key={entry.name}
              entry={entry}
              selectedEntry={selectedEntry}
              level={1}
              onSelectEntry={this._onSelectEntry}
            />
          ))}
          <div className="logout-button" onClick={() => appActions.logout()}>
            登出
          </div>
        </div>

        <div className="admin-tab-content">{this._renderContent()}</div>

        <div className="admin-rwd-toggle" onClick={this._toggleRwdSidebar}>
          <Icon.Apps size={24} color="white" />
        </div>
      </Wrapper>
    );
  }

  _renderHeader = () => {
    let { Header } = this.props;
    let { selectedEntry } = this.state;

    if (typeof Header === "string") {
      return <div className="site-title">{Header}</div>;
    } else if (typeof Header === "function") {
      // Component
      return <Header selectedEntry={selectedEntry} />;
    } else if (typeof Header === "object") {
      // Element
      return Header;
    }
  };

  _renderContent = () => {
    let { selectedEntry } = this.state;
    try {
      return this.props.children({
        cms: {
          entry: selectedEntry
        }
      });
    } catch (ex) {
      return <div>{selectedEntry.name}</div>;
    }
  };

  _onSelectEntry = entry => {
    let { entryFunctionHandler } = this.props;
    if (entry.type === "function") {
      entryFunctionHandler(entry);
    } else {
      this.setState({ selectedEntry: entry, rwdSidebarOpen: false });
    }
  };

  _toggleRwdSidebar = () => {
    let { rwdSidebarOpen } = this.state;
    this.setState({ rwdSidebarOpen: !rwdSidebarOpen });
  };
}

class CmsEntry extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: false
    };
  }

  render() {
    let { entry, level, selectedEntry, onSelectEntry } = this.props;
    let { expand } = this.state;
    let isSelected = selectedEntry.name === entry.name;

    return (
      <>
        <div
          className={
            "entry " + `level${level} ` + (isSelected ? "selected" : "")
          }
          onClick={this._onEntryClick}
        >
          <label>{entry.label}</label>

          {entry.entries && <div className="expand">{expand ? "-" : "+"}</div>}
        </div>

        {expand &&
          entry.entries.map(e => (
            <CmsEntry
              level={level + 1}
              entry={e}
              selectedEntry={selectedEntry}
              onSelectEntry={onSelectEntry}
              key={e.name}
            />
          ))}
      </>
    );
  }

  _onEntryClick = () => {
    let { entry, onSelectEntry } = this.props;
    let { expand } = this.state;

    if (entry.entries) {
      this.setState({ expand: !expand });
    } else {
      onSelectEntry(entry);
    }
  };
}

export default connect(
  null,
  ActionCreator
)(CmsDashboard);
