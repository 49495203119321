import React from "react";
import styled from "styled-components";
import { dateParser } from "../../../src/Utils/DateUtil";

const Wrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  & .row {
    display: flex;
    align-items: stretch;
    min-height: 50px;
    cursor: pointer;

    :hover {
      background-color: #f9f7f7;
    }

    .cell {
      display: flex;
      align-items: center;
      flex: 0;
      flex-shrink: 0;
      flex-basis: 100px;
      padding: 5px 10px;
      border-bottom: 1px solid #ccc;
    }

    .cell.header {
      font-weight: bold;
      background-color: #eee;
    }

    .cell:first-child {
      cursor: pointer;
      color: #d36d45;
    }

    .cell.header:first-child {
      color: black;
      text-decoration: none;
    }

    .cell:first-child:hover {
      font-weight: bold;
    }

    .cell:last-child {
      flex-grow: 1;
    }
  }
`;

const Cell = styled.div`
  ${props => props.css || ""}
`;

class Table extends React.Component {
  render() {
    let { model, instances, onCellClick } = this.props;
    return (
      <Wrapper>
        <TableHeader model={model} />

        {instances
          .sort((a, b) => new Date(b.created) - new Date(a.created))
          .map((instance, idx) => (
            <TableRow
              key={idx}
              no={idx}
              instance={instance}
              model={model}
              onCellClick={onCellClick}
            />
          ))}
      </Wrapper>
    );
  }
}

class TableRow extends React.Component {
  render() {
    let { model, instance, onCellClick, no } = this.props;

    return (
      <div className="row" onClick={() => onCellClick({ instance })}>
        {model.fields.map((field, idx) => {
          let extraProps = {};
          return (
            <Cell
              className="cell"
              key={idx}
              style={field.style || {}}
              {...extraProps}
            >
              {(() => {
                if (field.name === "no") {
                  return `${no + 1}.`;
                }
                if (field.name === "number" && instance.valid === false) {
                  return `${instance.number}(已逾期)`;
                }
                if (field.name === "valid_duration") {
                  return instance["valid_duration"] > 0
                    ? `${instance["valid_duration"] - 3} 天`
                    : `X`; // for demand, valid_duration in BE give customer 3 extra days buffer
                }
                if (field.name === "is_subscribe") {
                  if (instance.order && instance.order.is_subscribe_order) {
                    if (
                      instance.order.rtn_app &&
                      instance.order.rtn_app.is_sub_canceled
                    ) {
                      return `已成功取消訂閱`;
                    } else {
                      return `V`;
                    }
                  } else {
                    return ``;
                  }
                }
                if (field.name === "created") {
                  return `${instance.created.split("T")[0]}`;
                }

                return instance[field.name];
              })()}
            </Cell>
          );
        })}
      </div>
    );
  }
}

class TableHeader extends React.Component {
  render() {
    let { model } = this.props;
    return (
      <div className="row">
        {model.fields.map((field, idx) => (
          <Cell className="cell header" key={idx} style={field.style || {}}>
            {field.label || field.name}
          </Cell>
        ))}
      </div>
    );
  }
}

export default Table;
