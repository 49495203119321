import React from "react";
import styled from "styled-components";
import SelectSearch from "../InputField.select-search";

const UPLOAD_ICON =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABT0lEQVR4Ae3PgYaCARBF4QEkEbFvu5gWFhDQsywEgkAI9nFm7QCl4VK/MZwDuOB+tkxERERERETh4bPv/+ej72c++n7mo+9nPvp+5qPvZz76fuaj72c++n7mo+9nPvp+5qPvZz76fuZT7wuE/vs/wqISGu6fYhWPreKkE9rvm8VDZqET+u8/A+iEzvvnvF8AknDWCR3312YlIIu1Tmi8XwN0Qst9ASATGu6rgCRcGgjl/UveVwFZbEpCw/2NmQ7QCY33FYBOaLivAZJwXYwg3L9W9yVAFtvFCML9rZkOaCPI9wVAA0G/LwFqwm0hQnzp9yVAUeyeEvb2enGI+271fR0gEb5NTybcYmf2OkAgHOx9xVG4LwAEwm99/2VCfV8AiMVHEo72/mIv3BcAAuHTmhMA/QEAAAAAAAAAAAAAAAAAAAAAADQEAAAREREREdEfFzanSmVq68wAAAAASUVORK5CYII=";

const Wrapper = styled.div`
  display: flex;

  & > label {
    display: block;
    font-weight: 600;
    margin-right: 10px;
    width: 160px;
  }

  & input[type="checkbox"] + label {
    width: 500px;
    margin-left: 10px;
  }

  & input[type="checkbox"] {
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #cacece;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 9px;
    border-radius: 3px;
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
  }

  & input[type="checkbox"]:active,
  input[type="checkbox"]:checked:active {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px 1px 3px rgba(0, 0, 0, 0.1);
  }

  & input[type="checkbox"]:checked {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05),
      inset 15px 10px -12px rgba(255, 255, 255, 0.1);
    color: #99a1a7;
  }

  & input[type="checkbox"]:checked:after {
    content: "\\2713";
    font-size: 13px;
    position: absolute;
    top: -1px;
    left: 2px;
    color: teal;
  }

  & > a {
    color: #4a90e2;
    font-size: 14px;
  }

  & > input[type="text"],
  textarea {
    width: 200px;
    border: 1px solid #979797;
    border-radius: 6px;
    padding: 8px 10px;
    outline: none;
    color: #101d1f;

    &:focus {
      box-shadow: 0px 0px 0px 1.5px lightgrey;
    }
  }

  & > textarea {
    height: 100px;
  }

  & > img {
    width: 300px;
    align-self: flex-start;
  }

  & > .input-upload-wrapper {
    position: relative;
    & > input {
      opacity: 0;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    & > button {
      background-color: #4a90e2;
      color: white;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 5px;
      padding-right: 10px;

      & > img {
        width: 20px;
        margin-right: 5px;
      }

      &:focus {
        outline: none;
      }
    }
  }

  & .margin-left-10 {
    margin-left: 10px;
  }

  margin-bottom: 20px;
`;

class InputField extends React.Component {
  static defaultProps = {
    extra: {}
  };

  constructor(props) {
    super(props);
    this.state = {
      choices: Array.isArray(this.props.choices) ? this.props.choices : []
    };
  }

  componentDidMount() {
    let { fnFetchChoices } = this.props.extra;
    if (fnFetchChoices) {
      fnFetchChoices()
        .then(results => {
          this.setState({ choices: results });
        })
        .catch(console.warn);
    }
  }

  render() {
    let {
      name,
      objName,
      obj,
      value,
      setState,
      label = "",
      type = "text",
      required = false,
      disabled = false,
      inputProps = {}
    } = this.props;
    let { fnGetLabel, fnSearchChoices } = this.props.extra;
    let { choices } = this.state;

    if (!name || !objName || !obj || !setState) {
      throw Error("missing required arguments");
    }

    if (value === undefined) {
      value = "";
    }

    let props = {
      name,
      type,
      value,
      required,
      disabled,
      onChange: createOnChangeHandler({ objName, obj, name, setState, type })
    };

    if (type === "textarea") {
      delete props.type;
    } else if (type === "checkbox") {
      delete props.value;
      props.checked = value;
    } else if (["file", "image", "audio", "video"].indexOf(type) > -1) {
      delete props.value;
      props.type = "file";
      type === "image" && (props.accept = "image/*");
      type === "audio" && (props.accept = "audio/*");
      type === "video" && (props.accept = "video/*");
    }

    props = { id: name, ...props, ...inputProps };

    if (type === "textarea") {
      return (
        <Wrapper>
          <label htmlFor={name}>{label || name}</label>
          <textarea {...props} />
        </Wrapper>
      );
    } else if (type === "checkbox") {
      return (
        <Wrapper>
          <input {...props} />
          <label htmlFor={name}>{label || name}</label>
        </Wrapper>
      );
    } else if (type === "select") {
      return (
        <Wrapper>
          <label htmlFor={name}>{label || name}</label>
          <select {...props}>
            {choices.map(choice => (
              <option key={choice.value} value={choice.value}>
                {choice.label}
              </option>
            ))}
          </select>
        </Wrapper>
      );
    } else if (type === "select-ext") {
      return (
        <Wrapper>
          <label htmlFor={name}>{label || name}</label>
          <SelectSearch
            {...props}
            fnGetLabel={fnGetLabel}
            fnSearchChoices={fnSearchChoices}
            choices={choices}
          />
        </Wrapper>
      );
    } else if (["file", "image", "audio", "video"].indexOf(type) > -1) {
      const getFilename = url => {
        let tokens = url.split("/");
        return tokens[tokens.length - 1];
      };

      let url = "",
        imgObjUrl = null;
      if (typeof value === "string") {
        // if we have just selected a file, the value will be an File object,
        // otherwise if it is returning from our api server, it will typically be an url
        url = value;
      } else if (typeof value === "object") {
        if (value.type.indexOf("image") === 0) {
          imgObjUrl = URL.createObjectURL(value);
        }
      }

      return (
        <Wrapper>
          <label htmlFor={name}>{label || name}</label>
          {url && <a href={url}>{getFilename(url)}</a>}
          {imgObjUrl && <img src={imgObjUrl} />}
          <div className="input-upload-wrapper margin-left-10">
            <input {...props} />
            <button>
              <img src={UPLOAD_ICON} />
              上傳新圖片
            </button>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <label htmlFor={name}>{label || name}</label>
        <input {...props} />
      </Wrapper>
    );
  }
}

const createOnChangeHandler = ({ objName, obj, name, setState, type }) => e => {
  const splitedNames = name.split(".");
  const getValue = e => {
    if (type === "checkbox") {
      return e.target.checked;
    } else if (["file", "image", "audio", "video"].indexOf(type) > -1) {
      return e.target.files[0];
    }
    return e.target.value;
  };

  if (splitedNames.length === 1) {
    setState({
      [objName]: {
        ...obj,
        [name]: getValue(e)
      }
    });
  } else if (splitedNames.length === 2) {
    // nested 2 level deep
    // if top level data is a string
    // then we first parse it back to an object
    let [firstName, secondName] = splitedNames;
    let data = obj[firstName];
    if (typeof data === "string") {
      try {
        data = JSON.parse(data);
      } catch (ex) {
        console.warn(ex);
        data = {};
      }
    } else if (!data) {
      data = {};
    }

    let nextObj = {
      ...obj,
      [firstName]: JSON.stringify({
        ...data,
        [secondName]: getValue(e)
      })
    };

    setState({
      [objName]: nextObj
    });
  }
};

export default InputField;
