import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { withPage } from "../../Page";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import * as Widget2 from "../../Components/Widget2";
import * as Widget from "../../Components/Widget";
import Login from "../../Components/Login";
import * as Svg from "../../Components/Login/SvgItems";
import CmsDashboard from "../../Components/CmsDashboard";
import OrderList from "../../Components/OrderList";
import CmsTableFactory from "../../Components/CmsTableFactory";
import PhoneDetail from "../../Components/PhoneDetail";
import * as L from "../../Utils/Lang";
import AppConfig from "../../Config";

const HeaderContainer = styled.div`
  display: flex;
  padding: 20px;
  width: 200px;

  & > .user-profile {
    margin: 0 auto;
    overflow: hidden;
  }
`;

const LoginWrapper = styled(Widget2.Row)`
  & > div {
    margin: 50px;
  }

  & .image {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
`;

const PhoneTable = CmsTableFactory({
  title: "Phone",
  actionFetch: "getPhoneList",
  FormComp: PhoneDetail,
  ActionCreator,
  model: {
    fields: [
      { name: "no", label: "no" },
      {
        name: "number",
        label: "手機號碼",
        style: {
          flexBasis: 200,
          fontSize: 14,
          color: "#575757"
        }
      },
      {
        name: "valid_duration",
        label: "使用期限",
        style: {
          flexBasis: 150,
          fontSize: 14,
          color: "#575757"
        }
      },
      {
        name: "created",
        label: "購買日期",
        style: {
          flexBasis: 150,
          fontSize: 14,
          color: "#575757"
        }
      },
      {
        name: "max_msg",
        label: "簡訊數上限",
        style: {
          flexBasis: 150,
          fontSize: 14,
          color: "#575757"
        }
      },
      {
        name: "is_subscribe",
        label: "是否為訂閱門號",
        style: {
          flexBasis: 150,
          fontSize: 14,
          color: "#575757"
        }
      }
    ]
  }
});

const renderLogin = ({ onClick }) => {
  return (
    <Widget2.Button
      textColor={"white"}
      color={AppConfig.global.themeColor}
      css={" height:40px; width:300px; font-size:1rem;"}
      onClick={onClick}
    >
      {`${L.s("login")} / ${L.s("register")}`}
    </Widget2.Button>
  );
};

class ProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    setTimeout(() => {
      //wait for auto login
      this.setState({ isLoading: false });
    }, 1000);
  }

  render() {
    let { profile } = this.props;

    if (this.state.isLoading) {
      return (
        <Widget2.MaxWidth width={800} extraCss="padding: 20px">
          <Widget2.Center>
            <Widget.Spinner />
          </Widget2.Center>
        </Widget2.MaxWidth>
      );
    }
    if (!profile) {
      return (
        <Widget2.MaxWidth width={800} extraCss="padding: 20px">
          <Widget2.Center>
            <LoginWrapper>
              <div className="image">
                <Svg.Hi color={AppConfig.global.themeColor} />
              </div>
              <Widget2.Col align="center" justify="center">
                <h2 style={{ margin: "30px 0" }}>請先登入或註冊</h2>
                <Login.Button renderLogin={renderLogin} />
              </Widget2.Col>
            </LoginWrapper>
          </Widget2.Center>
        </Widget2.MaxWidth>
      );
    }

    return (
      <CmsDashboard
        Header={this._headerJsx()}
        entries={[
          { name: "phone", label: "我的電話" },
          { name: "order", label: "我的訂單" }
        ]}
        entryFunctionHandler={this._onEntryFunction}
        css={`
          background-color: #eee8e3;
          padding: 50px;
          & > .admin-sidebar {
            background-color: white;
            border-radius: 5px;

            .site-title {
              color: #575757;
            }

            .entry {
              color: #575757;
            }

            .entry label {
              text-align: center;
            }

            .entry.selected {
              color: #d55421;
              background-color: #fef1ec;
            }

            .entry:hover {
              color: #f78254;
              background-color: #ffd3c3;
            }
          }

          @media screen and (max-width: 600px) {
            padding: 20px 0px 0px 0px;
          }
        `}
      >
        {({ cms }) => {
          let { entry } = cms;

          if (entry.name === "phone") {
            return <PhoneTable />;
          } else if (entry.name === "order") {
            return <OrderList />;
          }
        }}
      </CmsDashboard>
    );
  }

  _onEntryFunction = entry => {
    let { appActions } = this.props;
    if (entry.name === "logout") {
      appActions.logout().catch(console.warn);
    }
  };

  _headerJsx = () => {
    let { profile } = this.props;

    return (
      <HeaderContainer>
        <div className="user-profile">
          <p style={{ fontSize: 30, color: "#575757" }}>
            {this._getUserName()}
          </p>
        </div>
      </HeaderContainer>
    );
  };

  _getUserName = () => {
    let { profile } = this.props;
    if (profile.name) {
      //when username is social login name
      return profile.name;
    } else if (profile.user.username.indexOf("@") > -1) {
      //when username is email
      return profile.user.username.split("@", 1);
    } else {
      return profile.user.username;
    }
  };
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state)
    }),
    ActionCreator
  )(ProfilePage)
);
