import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ItemBrowser from "../ItemBrowser";
import Table from "../Table";
import Paging from "../Paging";
import SortingGroup from "../SortingGroup";
import * as Icon from "../Icon";
import * as Widget from "../Widget";
import Image from "../Image";

const Wrapper = styled.div`
  margin: 0px 20px;
  background-color: white;
  border-radius: 5px;
  & > h2 {
    border-radius: 5px 5px 0px 0px;
    font-size: 20px;
    padding: 20px;
    background-color: #d36d45;
    color: white;
  }

  & .buy-button {
    width: 110px;
    height: 30px;
    background-color: #d55421;
    color: white;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & .back-button {
    margin: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #d36d45;
  }
`;

const UiState = {
  LIST: 0,
  DETAIL: 1
};

const CmsTableFactory = ({
  title,
  model,
  actionFetch,
  FormComp,
  sortType,
  ActionCreator,
  options = {
    skipHeader: false
  }
}) => {
  class GeneratedTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        uiState: UiState.LIST,
        query: "",
        instance: null
      };

      this._goBack = () => this.setState({ uiState: UiState.LIST });
    }

    render() {
      let { appActions, navActions } = this.props;
      let { query, uiState, instance } = this.state;

      if (uiState === UiState.DETAIL) {
        return (
          <Wrapper>
            {!options.skipHeader && (
              <>
                <h2>{title}</h2>
                <div className="back-button" onClick={this._goBack}>
                  <Icon.ArrowBack size={18} color={"#D36D45"} />
                  回到我的電話列表
                </div>
              </>
            )}

            <FormComp
              instance={instance}
              goBack={this._goBack}
              css="padding: 10px;"
            />
          </Wrapper>
        );
      }

      return (
        <Wrapper>
          {title === "Phone" ? <h2>我的電話</h2> : <h2>MY {title} LIST</h2>}
          <ItemBrowser
            label={""}
            limit={10}
            fetchItems={appActions[actionFetch]}
            navPush={this._updateQueryFromUrl}
            query={query}
          >
            {({ mounted, loading, data, page, sort, search }) => {
              return (
                <>
                  {data.length == 0 && !loading && (
                    <div style={{ width: "100%" }}>
                      <Widget.Center css="padding: 20px;">
                        <div style={{ fontSize: 16, color: "#575757" }}>
                          您目前沒有任何號碼
                        </div>
                        <Image
                          src={"../../../images/empty-cart.png"}
                          css={"width: 80px; height: 80px; margin: 30px;"}
                        />
                        <div
                          className="buy-button"
                          onClick={() => navActions.push("/")}
                        >
                          BUY NOW
                        </div>
                      </Widget.Center>
                    </div>
                  )}
                  {sort && sortType && (
                    <SortingGroup sort={sort} type={sortType} />
                  )}
                  {loading && (
                    <div style={{ width: "100%" }}>
                      <Widget.Center css="padding: 20px;">
                        <Widget.Spinner color="orange" />
                      </Widget.Center>
                    </div>
                  )}
                  {data && data.length != 0 && (
                    <Table
                      model={model}
                      instances={data}
                      onCellClick={this._onCellClick}
                    />
                  )}

                  {page.pages && data.length != 0 && <Paging page={page} />}
                </>
              );
            }}
          </ItemBrowser>
        </Wrapper>
      );
    }

    _onCellClick = ({ instance }) => {
      this.setState({
        instance,
        uiState: UiState.DETAIL
      });
    };

    _updateQueryFromUrl = url => {
      let query = url.split("?")[1];
      if (query) {
        query = "?" + query;
      }
      this.setState({ query: query || "" });
    };
  }

  return connect(
    null,
    ActionCreator
  )(GeneratedTable);
};

export default CmsTableFactory;
