import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import OrderItem from "../OrderItem";
import * as Widget from "../Widget";
import Image from "../Image";

class OrderList extends Component {
  state = {
    orders: [],
    loading: false
  };

  componentDidMount() {
    let { appActions } = this.props;
    this.setState({ loading: true });
    appActions
      .getOrderList()
      .then(orders => this.setState({ orders }))
      .catch(err => 0)
      .then(() => this.setState({ loading: false }));
  }
  render() {
    let { orders, loading } = this.state;
    return (
      <Wrapper>
        <Title>訂單記錄</Title>
        {loading && (
          <div style={{ width: "100%" }}>
            <Widget.Center css="padding: 20px;">
              <Widget.Spinner color="orange" />
            </Widget.Center>
          </div>
        )}

        {orders.length == 0 && !loading && (
          <div style={{ width: "100%" }}>
            <Widget.Center css="padding: 20px;">
              <div style={{ fontSize: 16, color: "#575757" }}>
                您目前沒有任何訂單
              </div>
              <Image
                src={"../../../images/empty-cart.png"}
                css={"width: 80px; height: 80px; margin: 30px;"}
              />
            </Widget.Center>
          </div>
        )}

        {orders.map(o => (
          <OrderItem
            key={o.id}
            order={o}
            css={`
              margin-bottom: 10px;
            `}
          />
        ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: white;
  margin: 0px 20px;
  border-radius: 5px;
  @media screen and (max-width: 780px) {
    max-width: 758px;
    border: 1px solid #e1e1e1;
  }
  @media screen and (max-width: 450px) {
    max-width: 758px;
    border: 1px solid #e1e1e1;
  }
`;

const Title = styled.div`
  padding: 20px;
  font-size: 20px;
  color: #ffffff;
  background-color: #d36d45;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #e1e1e1;
`;

export default connect(
  (state, ownProps) => ({
    // profile: Selectors.getLoginUser(state),
  }),
  ActionCreator
)(OrderList);
